import { Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from "./pages/LandingPage";
import Dashboard  from "./pages/Dashboard";
import Signin from './pages/Signin';
import About from './pages/About';
import Contact from './pages/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import FaqsPage from './pages/FaqsPage';
import Join from './pages/Join';
// import Admission from './pages/Admission';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <div className="App"  style={{overflowX: "hidden"}}>
      <ScrollToTop />
      <Routes>        
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/faqs" element={<FaqsPage />} />
        <Route path="/apply" element={<Join />} />
        {/* <Route path="/admission" element={<Admission />} /> */}
      </Routes>
    </div>
  );
}

export default App;
