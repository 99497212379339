import React from 'react';
import '../styles/Handbook.css';

const Handbook = () => {
  const handbookSections = [
    {
      title: 'Expected Behavior',
      content: 'The following are some of the requirements of every student:',
      listItems: [
        'Be supportive of fellow students, both proactively and responsively',
        'Use inclusive language, and strive to see the person first before their gender, religion, culture, or race.',
        'Be collaborative.',
        'Be generous in both giving and accepting feedback.',
        'Express empathy towards fellow students.',
        'Be kind.',
      ],
    },
    {
      title: 'Module Repetition',
      content: 'There are a few reasons why you may be asked to repeat a module in the program:',
      listItems: [
        'Leave of Absence due to personal or financial circumstances.',
        'Not meeting a deadline in a module.',
        'NOTE: Students are allowed a maximum of 2 module repetition throughout the entire program.)'
      ],
    },
    // {
    //   title: 'Block Repetition',
    //   content: 'There are a few reasons why you may be asked to repeat a block in the program:',
    //   listItems: [
    //     'Missing deadlines for required block activities.',
    //     'Exceeding the code review limit.',
    //     'Failing to follow collaboration guidelines.',
    //     'Being absent from meetings without a valid excuse.',
    //   ],
    // },
  ];

  return (
    <>
      <div className='dashboard-component handbook-back'>
        <div className="dashboard-overlay"></div>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>Handbook</p>
        </div>

        <div style={{backgroundColor: "#f3f3fb", paddingBottom: "40px"}}>
          {handbookSections.map((section, index) => (
            <div key={index} className='handbook-items'>
              <div className='info-item'>
                <span className='purple bold size5'>{section.title}</span><br />
                <span className='size4'>
                  <p className='component-text' style={{textAlign: "left"}}>{section.content}</p>
                  <ul className='component-text handbook-list'>
                    {section.listItems.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </span>            
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Handbook;
